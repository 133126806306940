import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Badge } from 'react-bootstrap'
import dynamic from 'next/dynamic'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import styles from './style.module.scss'
// import articleStyles from '../style.module.scss'
import { convertHMS, dateCheck, formatDate, getArticleImg, getImgURL } from '@shared/utils'
import noImage from '@assets/images/placeholder/article-placeholder.jpg'
import { BLUR_DATA_URL_BASE64, DEFAULT_BLOG_READ } from '@shared/constants'
import calenderIcon from '@assets/images/icon/calender-icon.svg'
import clockIcon from '@assets/images/icon/clock-icon.svg'
import CustomLink from '@shared/components/customLink'

const MyImage = dynamic(() => import('@shared/components/myImage'))
const ArticleStyles = dynamic(() => import('@shared/components/article/articleStyle'))

function ArticleMedium({ data, isVideo }) {
  const { t } = useTranslation()
  return (
    <ArticleStyles>
      {(articleStyles) => (
        <article
          id={data?._id}
          className={`${articleStyles.article} ${styles.articleMedium} ${isVideo && articleStyles.video} light-bg br-lg c-transition`}
        >
          <Row className="gx-2 gx-md-3">
            <Col xs={5} sm={6}>
              <CustomLink
                href={`/${data?.oSeo?.sSlug || '404'}`}
                className={`${articleStyles.postimg} ${styles.postimg} d-block br-md overflow-hidden position-relative a-transition`}
                prefetch={false}
              >
                <>
                  {isVideo && (
                    <MyImage
                      src={getImgURL(data?.sThumbnailUrl) || noImage}
                      alt={data?.sTitle}
                      blurDataURL={BLUR_DATA_URL_BASE64}
                      placeholder="blur"
                      height={200}
                      width={320}
                      sizes="(max-width: 768px) 80px, 320px"
                    />
                  )}
                  {!isVideo && (
                    <MyImage
                      src={getArticleImg(data)?.sUrl || noImage}
                      alt={getArticleImg(data)?.sText || data?.sSrtTitle}
                      placeholder="blur"
                      blurDataURL={BLUR_DATA_URL_BASE64}
                      height={200}
                      width={320}
                      sizes="(max-width: 768px) 80px, 320px"
                    />
                  )}
                  {isVideo && (
                    <Badge
                      bg="primary"
                      className={`${articleStyles.badge} ${articleStyles.videoBadge} ${
                        isVideo && 'video-badge'
                      } position-absolute start-0 bottom-0 ms-2 mb-2`}
                    >
                      {convertHMS(data?.nDurationSeconds)}
                    </Badge>
                  )}
                  {data?.iEventId && (
                    <div className={`${articleStyles.livePost} position-absolute`}>
                      <div className={`${articleStyles.liveIcon} position-relative d-inline-block rounded-circle bg-danger`} />
                      {t('common:Live')}
                    </div>
                  )}
                </>
              </CustomLink>
            </Col>
            <Col xs={7} sm={6} className="d-flex flex-column justify-content-between">
              <div>
                {data?.oCategory?.sName && (
                  <Badge
                    bg={data?.ePlatformType === 'de' ? 'danger' : 'primary'}
                    className={`${styles.badge} ${articleStyles.badge} ${isVideo && 'video-badge'} mb-1`}
                  >
                    <CustomLink href={data?.oCategory?.oSeo?.sSlug || ''} prefetch={false}>
                      {data?.oCategory?.sName}
                    </CustomLink>
                  </Badge>
                )}
                <h3 className={`${styles.title} small-head mb-1`}>
                  <CustomLink href={`/${data?.oSeo?.sSlug || '404'}`} prefetch={false} className="overflow-hidden line-clamp-3">
                    {data?.sSrtTitle || data?.sTitle}
                  </CustomLink>
                </h3>
                {data?.sDescription && (
                  <p className={`${articleStyles.desc} ${styles.desc} small-text text-secondary line-clamp-3 overflow-hidden`}>
                    {data?.sDescription}
                  </p>
                )}
              </div>
              {(data?.dPublishDisplayDate || data?.dPublishDate || data?.nDuration?.toString()) && (
                <div className={`${articleStyles.articleInfo} ${styles.articleInfo} text-muted text-muted d-flex`}>
                  {(data?.dPublishDisplayDate || data?.dPublishDate) && (
                    <span className="d-flex align-items-center">
                      <span className={`${articleStyles.icon} ${styles.icon} d-block`}>
                        <MyImage src={calenderIcon} alt="Calender" />
                      </span>
                      {formatDate(dateCheck(data?.dPublishDisplayDate || data?.dPublishDate), 'DD MMM YYYY')}
                    </span>
                  )}
                  <span className="d-flex align-items-center">
                    <span className={`${articleStyles.icon} ${styles.icon} d-block`}>
                      <MyImage src={clockIcon} alt="Clock" />
                    </span>
                    {data?.nDuration > 0 ? data?.nDuration : DEFAULT_BLOG_READ} <Trans i18nKey="common:Minute" />
                  </span>
                </div>
              )}
            </Col>
          </Row>
        </article>
      )}
    </ArticleStyles>
  )
}
ArticleMedium.propTypes = {
  data: PropTypes.object,
  isVideo: PropTypes.bool
}
export default ArticleMedium
